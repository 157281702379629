import {api} from '@moveup/app-core';

require('json.date-extensions');

const axiosJSONDateParseParams = {
    transformResponse: data => JSON.parseWithDate(data),
};

const initialState = {};

const store = {
    state: initialState,
    mutations: {},
    getters: {},
    actions: {
        getReport(_, params) {
            return api.get(`report/${params.patientId}/${params.coupleId}`);
        },

        getReportPDF(_, params) {
            return api.get(`report/${params.patientId}/${params.coupleId}/pdf`, null, {
                responseType: 'arraybuffer',
                headers: {
                    Accept: 'application/pdf',
                },
            });
        },
    },
};

export default store;
