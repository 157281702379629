<template>
  <b-navbar v-if="!$route.query.hideNavBar" spaced wrapper-class="container hidden-print" >
    <template slot="brand">
      <b-navbar-item :to="{ path: '/report' }" tag="router-link">
        <img alt="moveUP" src="../assets/moveUP-symbol-green.svg"/>
      </b-navbar-item>
    </template>

    <template slot="end">
      <b-navbar-dropdown v-if="user && user._id" :label="$gettext('Account')">
        <b-navbar-item v-translate :to="{ path: '/logout' }" tag="router-link">Log out</b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {'hideNavbar': Boolean},
  computed: {
    ...mapState('auth', ['user']),
  },
};
</script>
<style>
@media print {
  .hidden-print {
    display: none !important;
  }
}
</style>
