import { checkAuth } from '@moveup/app-core/auth';
import { Login, Logout, NoAccess, Settings, SignUp } from '@moveup/common-components';

const ReportView = () => import('@/views/ReportView.vue');

const routes = [
	{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/logout',
		name: 'Logout',
		component: Logout,
	},
	{
		path: '/no-access',
		name: 'NoAccess',
		component: NoAccess,
	},
	{
		path: '/signup',
		name: 'SignUp',
		component: SignUp,
	},
	{
		path: '/dashboard',
		name: 'Report',
		component: ReportView,
		beforeEnter: checkAuth,
	},
	{
		path: '/settings',
		name: 'Settings',
		component: Settings,
		beforeEnter: checkAuth,
	},
];

export default routes;
