const app = {
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'moveUP',
		// all titles will be injected into this template
		titleTemplate: '%s | bariatrics report',
	},
	created() {
		// startup logic goes here
	},
};

export { app };
